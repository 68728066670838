import axios from 'axios';
import { setHeaderToken, removeHeaderToken } from '@/includes/auth';
import { defineStore } from 'pinia';

const localUser = localStorage.getItem('user');

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: {},
    token: '',
  }),
  getters: {
    isAuth: (state) => {
      return !!state.token;
    },
    avatar: (state) => {
      return state.user?.avatar
        ? process.env.VUE_APP_BASEURL_KEY + state.user?.avatar
        : '/img/icons/profile.svg';
    },
  },
  actions: {
    getUser() {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.token = localStorage.getItem('token');
    },
    login(payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/login/email', payload)
          .then(({ data }) => {
            const token = data.token;
            this.user = data.user;
            this.token = token;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(this.user));
            setHeaderToken(token);
            resolve(data);
          })
          .catch((error) => {
            localStorage.removeItem('token');
            this.user = null;
            this.isLogined = false;
            reject(error);
          });
      });
    },
    loginByGoogle(payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/login/google', payload)
          .then(({ data }) => {
            const token = data.token;
            this.user = data.user;
            this.token = token;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(this.user));
            setHeaderToken(token);
            resolve(data);
          })
          .catch((error) => {
            localStorage.removeItem('token');
            this.user = null;
            this.isLogined = false;
            reject(error);
          });
      });
    },
    async logout() {
      await axios.post('/auth/logout');
      this.user = null;
      this.token = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      removeHeaderToken();
    },
    register(payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/register', payload)
          .then(({ data }) => {
            const token = data.token;
            this.user = data.user;
            this.token = token;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(this.user));
            setHeaderToken(token);
            resolve(data);
          })
          .catch((error) => {
            localStorage.removeItem('token');
            this.user = null;
            this.isLogined = false;
            reject(error);
          });
      });
    },
    updateProfile(payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('profile', payload)
          .then(({ data }) => {
            this.user = data.user;
            localStorage.setItem('user', JSON.stringify(this.user));
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
