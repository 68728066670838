import axios from 'axios';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import ToastPlugin from 'vue-toast-notification';
import moment from 'moment';
import Popper from 'vue3-popper';
import { createHead } from '@vueuse/head';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/css/style.css';
import './assets/css/theme.css';
import './assets/script.js';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import VeeValidatePlugin from './includes/veeValidate';
import firebase from './includes/firebase';
import { setHeaderToken } from './includes/auth';
import { useAuthStore } from './store/authentication';

axios.defaults.baseURL = process.env.VUE_APP_API_KEY;

moment.locale('id');

const token = localStorage.getItem('token');
if (token) {
  setHeaderToken(token);
}

const app = createApp(App);
const pinia = createPinia();

app.use(router);
app.use(VeeValidatePlugin);
app.use(createHead());
app.use(ToastPlugin);
app.use(pinia);
app.use(VueTelInput);
app.component('Popper', Popper);

const { getUser } = useAuthStore();
getUser();

app.config.globalProperties.$baseUrl = {
  baseUrl() {
    return process.env.VUE_APP_BASEURL_KEY;
  },
  truncate(description, maxLength) {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + ' ...';
    }
    return description;
  },
  formatNumber(number) {
    return Intl.NumberFormat().format(number);
  },
};

app.mount('#app');
