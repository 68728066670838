<template>
  <footer class="footer">
    <div class="container sub-footer">
      <div
        class="d-flex flex_phone gap-4 justify-content-center align-items-center align-items-md-start gap-sm-5"
      >
        <img class="img_phone" src="@/assets/images/iphone.png" alt="" />
        <div class="mt-md-5 pt-xl-2">
          <h1 class="title">Simpan Play Venue di Homescreen</h1>
          <p class="sub1 mt-1">
            Akses Play Venue lebih mudah dengan menyimpan web Play Venue di layar
            homescreen smartphone kamu.
          </p>
        </div>
      </div>
    </div>
    <div class="violet d-flex flex-column">
      <div class="container">
        <div class="row gap-4 gap-lg-0 mb-5 mb-md-0 justify-content-between">
          <div class="col-lg-5">
            <img class="img_white" src="@/assets/images/playvenue-logo.svg" alt="" />
            <p class="sub2 mt-4">
              Sistem Pemesanan Arena Olahraga Kota Semarang Dinas Pemuda dan
              Olahraga Kota Semarang
            </p>
          </div>
          <div class="col-lg-7">
            <div class="row justify-content-between">
              <div class="col-md-7 col-lg-8 mb-5 mb-md-3">
                <div class="d-flex gap_footer justify-content-lg-center">
                  <div>
                    <RouterLink to="/contact" class="sub2 mb-2"
                      >Contact</RouterLink
                    >
                    <RouterLink to="#" class="sub2 mb-2">Copyright</RouterLink>
                    <RouterLink to="/term-and-condition" class="sub2"
                      >Term and Condition</RouterLink
                    >
                  </div>
                  <div>
                    <RouterLink to="#" class="sub2 mb-2">Help</RouterLink>
                    <RouterLink to="/privacy-policy" class="sub2 mb-2"
                      >Privacy Policy</RouterLink
                    >
                    <RouterLink to="#" class="sub2"
                      >DISPORA Kota Semarang</RouterLink
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-lg-4">
                <div class="d-flex gap-4 justify-content-md-end">
                  <a
                    v-for="(value, key) in socmeds"
                    :key="key"
                    :href="value.link"
                    target="_blank"
                    class="img_socmed"
                  >
                    <img
                      class="img_white"
                      :src="$baseUrl.baseUrl() + value.icon"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <p class="sub3 text-center">
          © 2023 Play Venue - Dinas Pemuda dan Olahraga Kota Semarang All rights
          reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";

const socmeds = ref([]);

const getSocmeds = async () => {
  const { data } = await axios.get("/social_media");
  socmeds.value = data.social_media;
};

onMounted(() => {
  getSocmeds();
});
</script>

<style scoped>
.title {
  font-weight: 800;
  font-size: 2rem;
  line-height: 71px;
  font-family: var(--plusjakarta) !important;
}
.sub1 {
  font-weight: 600;
  font-size: 1.125rem;
  width: 85%;
  line-height: 2rem;
  letter-spacing: 0.005em;
}
.sub3 {
  font-weight: 300;
  font-family: var(--poppins);
  letter-spacing: 0.05em;
  font-size: 0.875rem;
}
.sub2 {
  font-weight: 500;
  line-height: 1.875rem;
  letter-spacing: 0.005em;
  color: white;
  display: block;
}
a.sub2 {
  font-size: 0.875rem;
}
a.sub2:hover {
  color: var(--orange3) !important;
}
.img_phone {
  width: 225px;
}

.img_white {
  filter: brightness(0) invert(1);
}
.violet {
  /* background-color: #514eff; */
  background-color: var(--red);
  background-size: cover;
  padding: 2rem 0 1.5rem;
  color: #fff;
  z-index: 9999;
  position: absolute;
  width: 100%;
  bottom: -2rem;
}
.footer {
  /* background: linear-gradient(
      180deg,
      rgba(250, 30, 14, 0.35) 0%,
      rgba(250, 30, 14, 0.7) 25.69%,
      #FA1E0E 100%
    ),
    url(@/assets/images/bg-footer.png); */
   background: linear-gradient(
      180deg,
      rgba(226, 88, 34, 0.20) 0%,
      rgba(226, 88, 34, 0.25) 25.69%,
      #bd2000 100%
    ),
    url(@/assets/images/bg-footer.png);
  width: 100%;
  background-repeat: no-repeat !important;
  color: #fff;
  background-size: cover;
  background-position: bottom;
  z-index: 1;
  padding: 2rem 0;
}
footer {
  position: relative;
}
.gap_footer {
  gap: 20%;
}
.img_socmed:hover {
  transform: translateY(-0.125rem);
  animation-name: slideIn;
  animation-duration: 0.2s;
}
@keyframes slideIn {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-0.125rem);
  }
}
@media only screen and (max-width: 1199.98px) {
  .title {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
  .sub1 {
    font-size: 1.125rem;
    line-height: 2rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .gap_footer {
    gap: 10%;
  }
  .sub1 {
    font-size: 1rem;
    line-height: 1.75rem;
    width: 90%;
  }
  .img_phone {
    width: 200px;
  }
}
@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  .title {
    font-size: 1.25rem;
    line-height: 2.5rem;
  }
  .sub1 {
    font-size: 0.875rem;
    line-height: 1.5rem;
    width: 90%;
  }
  .img_phone {
    width: 200px;
  }
  .sub2,
  .sub3 {
    font-size: 0.875rem;
    line-height: 1.875rem;
  }
  .violet {
    bottom: -8rem !important;
  }
  .footer {
    padding: 2rem 0 !important;
  }
  p.sub2 {
    width: 60%;
  }
}

@media screen and (max-width: 767.98px) {
  .violet {
    bottom: -31rem !important;
    padding-bottom: 5rem;
  }
  .title {
    font-size: 1.25rem;
    line-height: 2.25rem;
  }
  .sub1 {
    font-size: 0.875rem;
    line-height: 1.75rem;
    width: 100%;
  }
  .footer {
    font-size: 0.875rem;
  }
  .img_phone {
    width: 6rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .title,
  .sub1 {
    text-align: center;
  }
  .violet {
    bottom: -21rem !important;
  }
  .sub-footer {
    padding-bottom: 8rem;
  }
  .flex_phone {
    flex-direction: column;
  }
  .flex_phone img {
    order: 2 !important;
  }
}

@media only screen and (max-width: 420px) {
  .violet {
    bottom: -24rem !important;
  }
}
</style>
