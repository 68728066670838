<template>
    <component v-if="layout === 'authLayout'" :is="authLayout">
        <router-view></router-view>
    </component>
    <component v-else-if="layout === 'homeLayout'" :is="homeLayout">
        <router-view></router-view>
    </component>
    <component v-else-if="layout === 'defaultLayout'" :is="defaultLayout">
        <router-view></router-view>
    </component>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import authLayout from "./layouts/authLayout.vue";
import homeLayout from "./layouts/homes.vue";
import defaultLayout from "./layouts/default.vue";

const route = useRoute();

const layout = computed(() => route.meta.layout);
</script>
