<template>
    <NavbarSolid />
    <router-view />
    <NavbarBottom />
</template>

<script setup>
import { defineComponent } from "vue";
import NavbarSolid from "@/components/NavbarSolid.vue";
import NavbarBottom from "@/components/NavbarBottom.vue";

defineComponent({
    name: "authLayout",
});
</script>
