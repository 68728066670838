import firebase from 'firebase';



// import { getAnalytics } from 'firebase/analytics';
// Add more Firebase modules if needed
// import firebase from 'firebase/app';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: 'AIzaSyDyooZu4mdlGxfhEf6WX46TABoPrVPAT84',
//   authDomain: 'spora-67c0d.firebaseapp.com',
//   projectId: 'spora-67c0d',
//   storageBucket: 'spora-67c0d.appspot.com',
//   messagingSenderId: '805838588530',
//   appId: '1:805838588530:web:c5ae4a6b258bc48f6fd0eb',
//   measurementId: 'G-3EQVLCZ7WK',
// };

const firebaseConfig = {
  apiKey: "AIzaSyDVTDkyz1-XoqcudM7IqlhQ3EHXbLEtdTc",
  authDomain: "play-value.firebaseapp.com",
  projectId: "play-value",
  storageBucket: "play-value.appspot.com",
  messagingSenderId: "668168726798",
  appId: "1:668168726798:web:0861d393957e94405c4f5f",
  measurementId: "G-91D08XGN89"
};

firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics()

// Initialize Firebase

// export const firebase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
