<template>
  <div class="fixed-top w-100">
    <div class="position-absolute top-0 w-100">
      <nav
        class="navbar navbar-expand-lg"
        :class="
          navigationStyle || open ? 'bg-white shadow_menu' : 'bg-transparent'
        "
      >
        <div class="container">
          <RouterLink class="navbar-brand" to="/">
            <img
              src="@/assets/images/playvenue-logo.svg"
              class="logo"
              :class="navigationStyle || open ? '' : 'img_white'"
              alt=""
            />
          </RouterLink>
          <div class="d-flex gap-3">
            <button
              class="navbar-toggler bg-none border-0 shadow-none p-0 d-none d-md-block d-lg-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click.prevent="open = !open"
            >
              <img
                src="@/assets/images/icons/hamburger-dark.svg"
                :class="navigationStyle || open ? '' : 'img_white'"
                height="30"
                alt=""
              />
            </button>

            <div
              v-if="!isAuth"
              class="my-lg-0 d-flex justify-content-center justify-content-lg-end d-md-none"
            >
              <RouterLink to="/login" class="btn_signin">Sign In</RouterLink>
            </div>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex text-center gap-lg-3"
            >
              <li class="nav-item">
                <RouterLink
                  class="nav-link active"
                  :class="navigationStyle || open ? 'text-dark' : 'text-white'"
                  aria-current="page"
                  to="/"
                  >Beranda</RouterLink
                >
              </li>
              <li class="nav-item">
                <RouterLink
                  class="nav-link active"
                  :class="navigationStyle || open ? 'text-dark' : 'text-white'"
                  aria-current="page"
                  to="/events"
                  >Kegiatan</RouterLink
                >
              </li>
              <li class="nav-item">
                <RouterLink
                  class="nav-link active"
                  :class="navigationStyle || open ? 'text-dark' : 'text-white'"
                  aria-current="page"
                  to="/articles"
                  >Artikel</RouterLink
                >
              </li>
              <li class="nav-item">
                <RouterLink
                  class="nav-link active"
                  :class="navigationStyle || open ? 'text-dark' : 'text-white'"
                  aria-current="page"
                  to="/booking"
                  >Booking</RouterLink
                >
              </li>
            </ul>
            <div
              v-if="!isAuth"
              class="my-3 my-lg-0 d-flex justify-content-center justify-content-lg-end"
            >
              <RouterLink to="/login" class="btn_signin">Sign In</RouterLink>
            </div>
            <RouterLink
              v-if="isAuth"
              to="/profile"
              class="my-lg-0 d-flex justify-content-center justify-content-lg-end d-none d-md-flex"
            >
              <img :src="avatar" class="profile" alt="" />
            </RouterLink>
          </div>
          <RouterLink
            v-if="isAuth"
            to="/profile"
            class="my-lg-0 d-flex justify-content-center justify-content-lg-end d-md-none"
          >
            <img :src="avatar" class="profile" alt="" />
          </RouterLink>
        </div>
      </nav>
    </div>
  </div>
</template>
<script setup>
import { useAuthStore } from '@/store/authentication';
import { onMounted, ref, computed } from 'vue';

const auth = useAuthStore();
const navigationStyle = ref(false);
const tab = ref(false);
const open = ref(false);

const isAuth = computed(() => auth.isAuth);
const avatar = computed(() => auth.avatar);

const onScroll = (p) => {
  if (p.currentTarget.scrollY > 20) {
    navigationStyle.value = true;
  } else {
    navigationStyle.value = false;
  }
};

const onResize = () => {
  if (window.innerWidth > 991) {
    open.value = false;
  }
  // else if (window.innerWidth >= 768) {
  //   open.value = true;
  // } else {
  //   open.value = false;
  // }
};

onMounted(() => {
  window.addEventListener('scroll', onScroll);
  window.addEventListener('resize', onResize);
});
</script>
<style scoped>
img.profile {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.hamburger {
  filter: invert(1);
}
nav {
  font-family: var(--plusjakarta);
  padding: 0.75rem 0;
}
.btn_signin {
  color: white !important;
}
.img_white {
  filter: brightness(0) invert(1);
}
.btn_signin {
  background: var(--red);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.03em;
}
.nav-link {
  letter-spacing: 0.03em;
  font-weight: 500 !important;
}
a.router-link-active {
  color: var(--orange) !important;
  position: relative;
  font-weight: 800 !important;
}
a.btn_signin.router-link-active {
  color: white !important;
}
a.navbar-brand.router-link-active::before {
  content: '';
  background-color: white !important;
  opacity: 0;
}
.shadow_menu {
  box-shadow: rgb(127, 137, 161) 0px 0px 30px !important;
}

.logo {
  height: 1.5rem;
  width: 5rem;
}

@media only screen and (min-width: 992px) {
  a.router-link-active::before {
    content: '';
    position: absolute;
    width: 2.5rem;
    background-color: var(--orange);
    height: 3px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
  }
  a.navbar-brand.router-link-active::before {
    background-color: white !important;
  }
}

@media only screen and (max-width: 767.98px) {
  nav {
    padding: 0.5rem 0 !important;
  }
}
</style>
