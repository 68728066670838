<template>
  <NavbarSolid />
  <div class="overflow-hidden">
    <router-view />
  </div>
  <Footer />
  <NavbarBottom />
</template>

<script setup>
  import { defineComponent } from 'vue';
  import NavbarSolid from '@/components/NavbarSolid.vue';
  import NavbarBottom from '@/components/NavbarBottom.vue';
  import Footer from '@/components/Footer.vue';

  defineComponent({
    name: 'defaultLayout',
  });
</script>
