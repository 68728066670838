<template>
  <div class="nav-bottom d-md-none">
    <div class="container">
      <div class="row text-center">
        <div class="col-3">
          <router-link to="/">
            <img
              src="@/assets/images/icons/home.svg"
              class="icon_menu"
              alt=""
            />
            <p class="menu mt-1">Beranda</p>
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/events">
            <img
              src="@/assets/images/icons/event.svg"
              class="icon_menu"
              alt=""
            />
            <p class="menu mt-1">Kegiatan</p>
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/articles">
            <img
              src="@/assets/images/icons/article.svg"
              class="icon_menu"
              alt=""
            />
            <p class="menu mt-1">Artikel</p>
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/booking">
            <img
              src="@/assets/images/icons/booking-menu.svg"
              class="icon_menu"
              alt=""
            />
            <p class="menu mt-1">Booking</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.nav-bottom {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -4px 8px rgba(43, 43, 43, 0.2);
  background-color: white;
  padding: 0.5rem 0;
}
.icon_menu {
  width: 1.125rem;
}
.menu {
  font-size: 0.75rem;
  color: black;
}
.router-link-active p {
  color: var(--red) !important;
}
.router-link-active .icon_menu {
  filter: invert(69%) sepia(61%) saturate(7238%) hue-rotate(348deg)
    brightness(92%) contrast(92%);
}
</style>
