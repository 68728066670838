import { Form as VeeForm, Field as VeeField, defineRule, ErrorMessage, configure } from 'vee-validate';
import { required, min, email } from '@vee-validate/rules';

export default {
  install(app) {
    app.component('VeeField', VeeField);
    app.component('VeeForm', VeeForm);
    app.component('ErrorMessage', ErrorMessage);

    defineRule('email_required', required);
    defineRule('password', required);
    defineRule('min', min);
    defineRule('email', email);

    configure({
      generateMessage: (ctx) => {
        const messages = {
          email_required: `Email wajib diisi`,
          password: `Kata sandi wajib diisi`,
          email: `Email tidak valid`,
          min: `Kata sandi minimal 8 karakter`,
        };

        const message = messages[ctx.rule.name] ? messages[ctx.rule.name] : `${ctx.field} tidak cocok`;

        return message;
      },
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: true,
      validateOnModelUpdate: true,
    });
  },
};
