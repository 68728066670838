<template>
    <Navbar />
    <div class="overflow-hidden">
        <router-view />
    </div>
    <Footer />
    <NavbarBottom />
</template>

<script setup>
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import NavbarBottom from "@/components/NavbarBottom.vue";
import Footer from "@/components/Footer.vue";

defineComponent({
    name: "homeLayout",
});
</script>
