import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../store/authentication';

const routes = [
  {
    path: '/',
    name: 'home',
    component: function () {
      return import('../views/home/index.vue');
    },
    meta: {
      layout: 'homeLayout',
    },
  },
  {
    path: '/arena',
    name: 'arena',
    component: function () {
      return import('../views/arena/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/booking',
    name: 'booking',
    component: function () {
      return import('../views/booking/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/booking/:id',
    name: 'bookingDetail',
    component: function () {
      return import('../views/booking/_id/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: function () {
      return import('../views/checkout/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
      auth: true,
    },
  },
  {
    path: '/payment/:id',
    name: 'payment',
    component: function () {
      return import('../views/checkout/payment.vue');
    },
    meta: {
      layout: 'defaultLayout',
      auth: true,
    },
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: function () {
      return import('../views/checkout/confirmation.vue');
    },
    meta: {
      layout: 'defaultLayout',
      auth: true,
    },
  },
  {
    path: '/articles',
    name: 'articles',
    component: function () {
      return import('../views/article/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/articles/:slug',
    name: 'detailArticle',
    component: function () {
      return import('../views/article/_slug/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/events',
    name: 'events',
    component: function () {
      return import('../views/event/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/edit-profile',
    name: 'edit-profile',
    component: function () {
      return import('../views/profile/edit-profile/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
      auth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: function () {
      return import('../views/profile/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
      auth: true,
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: function () {
      return import('../views/profile/change-password/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
      auth: true,
    },
  },
  {
    path: '/my-booking',
    name: 'my-booking',
    component: function () {
      return import('../views/profile/my-booking/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
      auth: true,
    },
  },
  {
    path: '/event/:slug',
    name: 'event',
    component: function () {
      return import('../views/event/_slug/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/signin/index.vue');
    },
    meta: {
      layout: 'authLayout',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: function () {
      return import('../views/signup/index.vue');
    },
    meta: {
      layout: 'authLayout',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: function () {
      return import('../views/forgot-password/index.vue');
    },
    meta: {
      layout: 'authLayout',
    },
  },
  {
    path: '/new-password',
    name: 'new-password',
    component: function () {
      return import('../views/new-password/index.vue');
    },
    meta: {
      layout: 'authLayout',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: function () {
      return import('../views/profile/logout/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
      auth: true,
    },
  },
  {
    path: '/term-and-condition',
    name: 'Term and Condition',
    component: function () {
      return import('../views/term/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: function () {
      return import('../views/privacy-policy/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: function () {
      return import('../views/contact/index.vue');
    },
    meta: {
      layout: 'defaultLayout',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  if (to.matched.some((record) => record.meta.auth && !auth.isAuth))
    next({ name: 'login' });
  else next();
});

export default router;
